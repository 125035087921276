<template>
  <div :class="['ai', cropsMark]">
    <!-- <router-view></router-view> -->
    <img
      class="ai-page-title"
      src="@/assets/image/ai/index/page_title.png"
      alt=""
      v-show="isMenubar"
    />
    <AiMenubar :isMenubar="isMenubar" @changeTab="changeTab"></AiMenubar>
    <AiSelectCrop
      :isCrop="isCrop"
      @loadCrop="loadCrop"
      @mouseEnterCrop="mouseEnterCrop"
    ></AiSelectCrop>
    <AiSelect
      v-if="isSelect"
      :isSelect="isSelect"
      @closeSelect="closeSelect"
      @changeCrop="changeCrop"
    ></AiSelect>
    <AiLandSelect
      v-if="isLandSelect"
      :isLandSelect="isLandSelect"
      @closeLandSelect="closeLandSelect"
      @changeLand="changeLand"
    ></AiLandSelect>
    <AiProgress :isProgress="isProgress" :tabIndex="tabIndex" :cropsInfoMark="cropsInfoMark"></AiProgress>
    <MyNav></MyNav>
  </div>
</template>

<script>
export default {
  components: {
    AiSelect: () => import("./components/aiSelect.vue"),
    AiLandSelect: () => import("./components/aiLandSelect.vue"),
    AiMenubar: () => import("./components/aiMenubar.vue"),
    AiSelectCrop: () => import("./components/aiSelectCrop.vue"),
    AiProgress: () => import("./components/aiProgress.vue"),
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {
      isMenubar: true,
      isSelect: false,
      isLandSelect: false,
      isCrop: false,
      cropsMark: "",
      cropsInfoMark: "",
      tabIndex: "",
      isProgress: false,
    };
  },
  created() {
    sessionStorage.setItem("navLeft", "-100vh");
  },
  methods: {
    loadCrop(mark) {
      this.isSelect = false;
      this.isMenubar = false;
      this.isCrop = false;
      this.isProgress = true;
      this.cropsMark = "";
      this.cropsInfoMark = mark;
    },
    mouseEnterCrop(mark) {
      this.cropsMark = mark;
    },
    changeCrop() {
      this.isSelect = false;
      this.isMenubar = false;
      this.isCrop = true;
    },
    changeLand() {
      this.$router.push({
        name: "aiLands",
      });
      this.isLandSelect = false;
      this.isMenubar = false;
    },
    closeSelect() {
      this.isSelect = false;
    },
    closeLandSelect() {
      this.isLandSelect = false;
    },

    changeTab(index) {
      this.tabIndex = index;
      switch (index) {
        case 0:
          this.isSelect = true;
          break;
        case 1:
          this.isLandSelect = true;
          break;
        case 2:
          this.isSelect = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
.ai {
  width: 100%;
  height: 100vh;
  padding: 65px 0 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url("../../assets/image/ai/index/page_bg.png") center center
    no-repeat #071a2c;
  background-size: cover;
  &.xm {
    background: url("../../assets/image/ai/crop/xm_bg.jpg") center center
      no-repeat;
    background-size: cover;
  }
  &.dd {
    background: url("../../assets/image/ai/crop/dd_bg.jpg") center center
      no-repeat;
    background-size: cover;
  }
  &.dp {
    background: url("../../assets/image/ai/crop/dp_bg.jpg") center center
      no-repeat;
    background-size: cover;
  }
  &.sd {
    background: url("../../assets/image/ai/crop/sd_bg.png") center center
      no-repeat;
    background-size: cover;
  }
  .ai-page-title {
    width: 605px;
    margin-bottom: 188px;
  }
}
</style>
